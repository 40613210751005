<template>
  <section class="userApplications">
    <div class="userApplications__header">
      <p class="userApplications__title">Accesos aplicaciones</p>
      <iconic class="userApplications__iconEdit" name="editNew" v-if="couldEditUser" @click.native="openEditModal" />
    </div>
    <div class="userApplications__containerApps">
      <div class="userApplications__contentApp" v-for="app in userApps" :key="app.name">
        <p class="userApplications__title">{{ app.name }}</p>
        <div class="userApplications__contentDataRole">
          <img class="userApplications__logo" :src="app.icon" alt="logo" />
          <div>
            <p class="userApplications__text userApplications__text--title">Rol</p>
            <p class="userApplications__text">{{ app.role }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    data() {
      return {
        dicRoles: this.$global.dictionary.rolesInver,
        rolesEs: this.$global.dictionary.roles_es_auto,
      };
    },
    computed: {
      user() {
        return this.$store.state.user.userSelected;
      },
      userApps() {
        const { rolesApp = {} } = this.user;
        const apps = this.$global.dictionary.apps_icons;

        const appsWithRole = Object.entries(rolesApp).map(([key, val]) => {
          const findApp = apps.find((app) => app.key === key);

          const role = val[0] ? this.rolesEs[this.dicRoles[val[0]]] : "";

          return { name: findApp.name, role, icon: findApp.icon };
        });
        return appsWithRole;
      },
      couldEditUser() {
        return this.$userData.role === "superadmin";
      },
    },
    methods: {
      openEditModal() {
        this.$store.dispatch("user/setInfoToEdit");

        const { role } = this.user;
        const numToSet = role === "model" ? 4 : 3;

        this.$store.state.user.toShowInMagnament = numToSet;
      },
    },
  };
</script>

<style lang="scss">
  .userApplications {
    @include Flex(column, flex-start, space-between);
    width: 100%;
    padding: $mpadding;
    gap: $mpadding / 2;
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    &__header {
      @include Flex(row, space-between);
    }
    &__title {
      font-family: $sec-font;
      font-size: 20px;
    }
    &__iconEdit {
      padding: 5px;
      font-size: 20px;
      color: $chicago;
      cursor: pointer;
    }
    &__containerApps {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
      gap: $mpadding;
      width: 100%;
    }
    &__contentApp {
      @include Flex(column, flex-start, flex-start);
      gap: 5px;
      padding: $mpadding / 1.5;
      background: #f8f8f8;
      border: 1px solid #d9d9d9;
      border-radius: 6px;
    }
    &__contentDataRole {
      @include Flex(row, space-between, flex-start);
      gap: 5px;
    }
    &__logo {
      width: 56px;
      height: 56px;
    }
    &__text {
      text-transform: capitalize;
      &--title {
        font-weight: 600;
      }
    }
  }
</style>
